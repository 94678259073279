/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {SyntheticEvent, useState} from "react";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import Swal from 'sweetalert2';

export function ResetPasswordPage() {
  const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [redirect, setRedirect] = useState(false);
    const {token} = useParams();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const data ={
            token,
            password,
            password_confirm: passwordConfirm
        }

         // Call API endpoint with selected filter value and update data accordingly
        axios.post(`api/reset`,data).then(res=>{


            if(res.data.status===200)
            {                
                Swal.fire("Success!",res.data.message,"success");

            }
            else 
            {
                Swal.fire("Warning",res.data.message, "warning");
            }
            });


        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to="/login"/>;
    }

    return <div>
    <div className='container py-5' style={{position: "absolute",top: "10%",left: "30%"}}>
        <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='card'>
                    <div className='card-header'>
                        <h4>Reset Password</h4>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={submit}>
                            <h1 className="h3 mb-3 fw-normal">Reset your password</h1>

                            <div className="form-floating">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                                       onChange={e => setPassword(e.target.value)}
                                />
                                <label htmlFor="floatingPassword">Password</label>
                            </div>

                            <div className="form-floating">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password Confirm"
                                       onChange={e => setPasswordConfirm(e.target.value)}
                                />
                                <label htmlFor="floatingPassword">Password Confirm</label>
                            </div>

                            <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='col-md-6'></div>
        </div>
    </div>
    </div>
}
