/* eslint-disable no-unused-vars */
import React, {SyntheticEvent, useState} from "react";
import axios from "axios";
import Swal from 'sweetalert2';

export function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
    const [notify, setNotify] = useState({
        show: false,
        error: false,
        message: ''
    });

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const data ={
            email:email,
        }


        axios.post(`api/forgot`,data).then(res=>{

            if(res.data.status===200)
            {                
                Swal.fire("Success!",res.data.message,"success");

            }
            else 
            {
                Swal.fire("Warning",res.data.message,"warning");
            }
            });
    }



    return  <div>
    <div className='container py-5' style={{position: "absolute",top: "10%",left: "30%"}}>
        <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='card'>
                    <div className='card-header'>
                        <h4>Forget Password?</h4>
                    </div>
                    <div className='card-body'>
                    <form onSubmit={submit}>
                        <h1 className="h3 mb-3 fw-normal">Please put your email</h1>

                        <div className="form-floating">
                            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>

                        <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Submit</button>
                    </form>
                    </div>
                </div>
            </div>
            <div className='col-md-6'></div>
        </div>
    </div>
    </div>
}
